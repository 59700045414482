/**
 * Slot Names
 */

export const slotNames = {
  below_response: "below_response",
  above_correct_answer: "above_correct_answer",
  below_correct_answer: "below_correct_answer",
  above_headline: "above_headline",
  below_headline: "below_headline",
  above_buttons: "above_buttons",
  below_buttons_1: "below_buttons_1",
  below_buttons_2: "below_buttons_2",
  below_buttons_3: "below_buttons_3",
  below_second_buttons_1: "below_second_buttons_1",
  below_second_buttons_2: "below_second_buttons_2",
  below_second_buttons_3: "below_second_buttons_3",
  page_level_1: "page_level_1",
  page_level_2: "page_level_2",
  page_level_3: "page_level_3",
  right_rail_upper: "right_rail_upper",
  right_rail_middle: "right_rail_middle",
  right_rail_lower: "right_rail_lower",
}

/**
 * Roles
 */

export const roles = {
  administrator: "Administrator",
  author: "Author",
  adsManager: "Ads Manager",
}

export const EMAIL_PATTERN = /^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+)(?:[A-Z0-9-]{2,63})$/

import { Action } from "utils"

/**
 * Initial State
 */

export type SlotObject = { slot: string; unitId: string }
export type Option = { id: string; value: string }

type selectedOptionsType = {
  selectedVendorOption: Option
  selectedUnitOption: Option
}

const selectedOptions = {
  selectedVendorOption: { id: undefined, value: undefined },
  selectedUnitOption: { id: undefined, value: undefined },
} as selectedOptionsType

export const initialVariantState = {
  slotsSelectedOptions: {
    belowResponse: {
      ...selectedOptions,
    },
    aboveCorrectAnswer: {
      ...selectedOptions,
    },
    belowCorrectAnswer: {
      ...selectedOptions,
    },
    aboveHeadline: {
      ...selectedOptions,
    },
    belowHeadline: {
      ...selectedOptions,
    },
    aboveButtons: {
      ...selectedOptions,
    },
    belowButtons1: {
      ...selectedOptions,
    },
    belowButtons2: {
      ...selectedOptions,
    },
    belowButtons3: {
      ...selectedOptions,
    },
    belowSecondButtons1: {
      ...selectedOptions,
    },
    belowSecondButtons2: {
      ...selectedOptions,
    },
    belowSecondButtons3: {
      ...selectedOptions,
    },
    pageLevel1: {
      ...selectedOptions,
    },
    pageLevel2: {
      ...selectedOptions,
    },
    pageLevel3: {
      ...selectedOptions,
    },
    rightRailUpper: {
      ...selectedOptions,
    },
    rightRailMiddle: {
      ...selectedOptions,
    },
    rightRailLower: {
      ...selectedOptions,
    },
  },

  slots: {
    belowResponse: { slot: "below_response", unitId: "" } as SlotObject,
    aboveCorrectAnswer: { slot: "above_correct_answer", unitId: "" } as SlotObject,
    belowCorrectAnswer: { slot: "below_correct_answer", unitId: "" } as SlotObject,
    aboveHeadline: { slot: "above_headline", unitId: "" } as SlotObject,
    belowHeadline: { slot: "below_headline", unitId: "" } as SlotObject,
    aboveButtons: { slot: "above_buttons", unitId: "" } as SlotObject,
    belowButtons1: { slot: "below_buttons_1", unitId: "" } as SlotObject,
    belowButtons2: { slot: "below_buttons_2", unitId: "" } as SlotObject,
    belowButtons3: { slot: "below_buttons_3", unitId: "" } as SlotObject,
    belowSecondButtons1: { slot: "below_second_buttons_1", unitId: "" } as SlotObject,
    belowSecondButtons2: { slot: "below_second_buttons_2", unitId: "" } as SlotObject,
    belowSecondButtons3: { slot: "below_second_buttons_3", unitId: "" } as SlotObject,
    pageLevel1: { slot: "page_level_1", unitId: "" } as SlotObject,
    pageLevel2: { slot: "page_level_2", unitId: "" } as SlotObject,
    pageLevel3: { slot: "page_level_3", unitId: "" } as SlotObject,
    rightRailUpper: { slot: "right_rail_upper", unitId: "" } as SlotObject,
    rightRailMiddle: { slot: "right_rail_middle", unitId: "" } as SlotObject,
    rightRailLower: { slot: "right_rail_lower", unitId: "" } as SlotObject,
  },

  defaultSlots: {
    belowResponse: { slot: "below_response", unitId: "" } as SlotObject,
    aboveCorrectAnswer: { slot: "above_correct_answer", unitId: "" } as SlotObject,
    belowCorrectAnswer: { slot: "below_correct_answer", unitId: "" } as SlotObject,
    aboveHeadline: { slot: "above_headline", unitId: "" } as SlotObject,
    belowHeadline: { slot: "below_headline", unitId: "" } as SlotObject,
    aboveButtons: { slot: "above_buttons", unitId: "" } as SlotObject,
    belowButtons1: { slot: "below_buttons_1", unitId: "" } as SlotObject,
    belowButtons2: { slot: "below_buttons_2", unitId: "" } as SlotObject,
    belowButtons3: { slot: "below_buttons_3", unitId: "" } as SlotObject,
    belowSecondButtons1: { slot: "below_second_buttons_1", unitId: "" } as SlotObject,
    belowSecondButtons2: { slot: "below_second_buttons_2", unitId: "" } as SlotObject,
    belowSecondButtons3: { slot: "below_second_buttons_3", unitId: "" } as SlotObject,
    pageLevel1: { slot: "page_level_1", unitId: "" } as SlotObject,
    pageLevel2: { slot: "page_level_2", unitId: "" } as SlotObject,
    pageLevel3: { slot: "page_level_3", unitId: "" } as SlotObject,
    rightRailUpper: { slot: "right_rail_upper", unitId: "" } as SlotObject,
    rightRailMiddle: { slot: "right_rail_middle", unitId: "" } as SlotObject,
    rightRailLower: { slot: "right_rail_lower", unitId: "" } as SlotObject,
  },

  loadDelayMin: 0,
  loadDelayMax: 0,
  loadDelayMinWarning: "",
  loadDelayMaxWarning: "",
  notes: undefined as string,

  defaultNotes: undefined as string,
}

/**
 * Dispatch Action Types
 */

export const variantActionTypes = {
  setSlotSelectedOptionsBelowResponse: "SET_SLOT_SELECTED_OPTIONS_BELOW_RESPONSE",
  setSlotSelectedOptionsAboveCorrectAnswer: "SET_SLOT_SELECTED_OPTIONS_ABOVE_CORRECT_ANSWER",
  setSlotSelectedOptionsBelowCorrectAnswer: "SET_SLOT_SELECTED_OPTIONS_BELOW_CORRECT_ANSWER",
  setSlotSelectedOptionsAboveHeadline: "SET_SLOT_SELECTED_OPTIONS_ABOVE_HEADLINE",
  setSlotSelectedOptionsBelowHeadline: "SET_SLOT_SELECTED_OPTIONS_BELOW_HEADLINE",
  setSlotSelectedOptionsAboveButtons: "SET_SLOT_SELECTED_OPTIONS_ABOVE_BUTTONS",
  setSlotSelectedOptionsBelowButtons1: "SET_SLOT_SELECTED_OPTIONS_BELOW_BUTTONS_1",
  setSlotSelectedOptionsBelowButtons2: "SET_SLOT_SELECTED_OPTIONS_BELOW_BUTTONS_2",
  setSlotSelectedOptionsBelowButtons3: "SET_SLOT_SELECTED_OPTIONS_BELOW_BUTTONS_3",
  setSlotSelectedOptionsBelowSecondButtons1: "SET_SLOT_SELECTED_OPTIONS_BELOW_SECOND_BUTTONS_1",
  setSlotSelectedOptionsBelowSecondButtons2: "SET_SLOT_SELECTED_OPTIONS_BELOW_SECOND_BUTTONS_2",
  setSlotSelectedOptionsBelowSecondButtons3: "SET_SLOT_SELECTED_OPTIONS_BELOW_SECOND_BUTTONS_3",
  setSlotSelectedOptionsPageLevel1: "SET_SLOT_SELECTED_OPTIONS_PAGE_LEVEL_1",
  setSlotSelectedOptionsPageLevel2: "SET_SLOT_SELECTED_OPTIONS_PAGE_LEVEL_2",
  setSlotSelectedOptionsPageLevel3: "SET_SLOT_SELECTED_OPTIONS_PAGE_LEVEL_3",
  setSlotSelectedOptionsRightRailUpper: "SET_SLOT_SELECTED_OPTIONS_RIGHT_RAIL_UPPER",
  setSlotSelectedOptionsRightRailMiddle: "SET_SLOT_SELECTED_OPTIONS_RIGHT_RAIL_MIDDLE",
  setSlotSelectedOptionsRightRailLower: "SET_SLOT_SELECTED_OPTIONS_RIGHT_RAIL_LOWER",

  setSlotBelowResponseUnitId: "SET_SLOT_BELOW_RESPONSE_UNIT_ID",
  setSlotAboveCorrectAnswerUnitId: "SET_SLOT_ABOVE_CORRECT_ANSWER_UNIT_ID",
  setSlotBelowCorrectAnswerUnitId: "SET_SLOT_BELOW_CORRECT_ANSWER_UNIT_ID",
  setSlotAboveHeadlineUnitId: "SET_SLOT_ABOVE_HEADLINE_UNIT_ID",
  setSlotBelowHeadlineUnitId: "SET_SLOT_BELOW_HEADLINE_UNIT_ID",
  setSlotAboveButtonsUnitId: "SET_SLOT_ABOVE_BUTTONS_UNIT_ID",
  setSlotBelowButtons1UnitId: "SET_SLOT_BELOW_BUTTONS_1_UNIT_ID",
  setSlotBelowButtons2UnitId: "SET_SLOT_BELOW_BUTTONS_2_UNIT_ID",
  setSlotBelowButtons3UnitId: "SET_SLOT_BELOW_BUTTONS_3_UNIT_ID",
  setSlotBelowSecondButtons1UnitId: "SET_SLOT_BELOW_SECOND_BUTTONS_1_UNIT_ID",
  setSlotBelowSecondButtons2UnitId: "SET_SLOT_BELOW_SECOND_BUTTONS_2_UNIT_ID",
  setSlotBelowSecondButtons3UnitId: "SET_SLOT_BELOW_SECOND_BUTTONS_3_UNIT_ID",
  setSlotPageLevel1UnitId: "SET_SLOT_PAGE_LEVEL_1_UNIT_ID",
  setSlotPageLevel2UnitId: "SET_SLOT_PAGE_LEVEL_2_UNIT_ID",
  setSlotPageLevel3UnitId: "SET_SLOT_PAGE_LEVEL_3_UNIT_ID",
  setSlotRightRailUpperUnitId: "SET_SLOT_RIGHT_RAIL_UPPER_UNIT_ID",
  setSlotRightRailMiddleUnitId: "SET_SLOT_RIGHT_RAIL_MIDDLE_UNIT_ID",
  setSlotRightRailLowerUnitId: "SET_SLOT_RIGHT_RAIL_LOWER_UNIT_ID",

  setDefaultSlotBelowResponseUnitId: "SET_DEFAULT_SLOT_BELOW_RESPONSE_UNIT_ID",
  setDefaultSlotAboveCorrectAnswerUnitId: "SET_DEFAULT_SLOT_ABOVE_CORRECT_ANSWER_UNIT_ID",
  setDefaultSlotBelowCorrectAnswerUnitId: "SET_DEFAULT_SLOT_BELOW_CORRECT_ANSWER_UNIT_ID",
  setDefaultSlotAboveHeadlineUnitId: "SET_DEFAULT_SLOT_ABOVE_HEADLINE_UNIT_ID",
  setDefaultSlotBelowHeadlineUnitId: "SET_DEFAULT_SLOT_BELOW_HEADLINE_UNIT_ID",
  setDefaultSlotAboveButtonsUnitId: "SET_DEFAULT_SLOT_ABOVE_BUTTONS_UNIT_ID",
  setDefaultSlotBelowButtons1UnitId: "SET_DEFAULT_SLOT_BELOW_BUTTONS_1_UNIT_ID",
  setDefaultSlotBelowSecondButtons1UnitId: "SET_DEFAULT_SLOT_BELOW_SECOND_BUTTONS_1_UNIT_ID",
  setDefaultSlotBelowSecondButtons2UnitId: "SET_DEFAULT_SLOT_BELOW_SECOND_BUTTONS_2_UNIT_ID",
  setDefaultSlotBelowSecondButtons3UnitId: "SET_DEFAULT_SLOT_BELOW_SECOND_BUTTONS_3_UNIT_ID",
  setDefaultSlotBelowButtons2UnitId: "SET_DEFAULT_SLOT_BELOW_BUTTONS_2_UNIT_ID",
  setDefaultSlotBelowButtons3UnitId: "SET_DEFAULT_SLOT_BELOW_BUTTONS_3_UNIT_ID",
  setDefaultSlotPageLevel1UnitId: "SET_DEFAULT_SLOT_PAGE_LEVEL_1_UNIT_ID",
  setDefaultSlotPageLevel2UnitId: "SET_DEFAULT_SLOT_PAGE_LEVEL_2_UNIT_ID",
  setDefaultSlotPageLevel3UnitId: "SET_DEFAULT_SLOT_PAGE_LEVEL_3_UNIT_ID",
  setDefaultSlotRightRailUpperUnitId: "SET_DEFAULT_SLOT_RIGHT_RAIL_UPPER_UNIT_ID",
  setDefaultSlotRightRailMiddleUnitId: "SET_DEFAULT_SLOT_RIGHT_RAIL_MIDDLE_UNIT_ID",
  setDefaultSlotRightRailLowerUnitId: "SET_DEFAULT_SLOT_RIGHT_RAIL_LOWER_UNIT_ID",

  setMinDelay: "SET_MIN_DELAY",
  setMaxDelay: "SET_MAX_DELAY",
  setMinDelayWarning: "SET_MIN_DELAY_WARNING",
  setMaxDelayWarning: "SET_MAX_DELAY_WARNING",
  setNotes: "SET_NOTES",

  setDefaultNotes: "SET_DEFAULT_NOTES",

  resetVariantState: "RESET_VARIANT_STATE",
}

/**
 * Reducer
 */

export type VariantActionData = {
  unitId?: string
  options?: {
    selectedVendorOption: Option
    selectedUnitOption: Option
  }
  notes?: string
  loadDelayMin?: number
  loadDelayMax?: number
  loadDelayMinWarning?: string
  loadDelayMaxWarning?: string
}

export type VariantState = typeof initialVariantState

export const variantReducer = (
  state: VariantState = initialVariantState,
  action: Action<VariantActionData>
): VariantState => {
  switch (action.type) {
    /**
     * Selected Options
     */

    case variantActionTypes.setSlotSelectedOptionsBelowResponse:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowResponse: action.data.options,
        },
      }

    case variantActionTypes.setSlotSelectedOptionsAboveCorrectAnswer:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          aboveCorrectAnswer: action.data.options,
        },
      }

    case variantActionTypes.setSlotSelectedOptionsBelowCorrectAnswer:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowCorrectAnswer: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsAboveHeadline:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          aboveHeadline: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowHeadline:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowHeadline: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsAboveButtons:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          aboveButtons: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowButtons1:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowButtons1: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowButtons2:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowButtons2: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowButtons3:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowButtons3: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowSecondButtons1:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowSecondButtons1: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowSecondButtons2:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowSecondButtons2: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsBelowSecondButtons3:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          belowSecondButtons3: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsPageLevel1:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          pageLevel1: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsPageLevel2:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          pageLevel2: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsPageLevel3:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          pageLevel3: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsRightRailUpper:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          rightRailUpper: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsRightRailMiddle:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          rightRailMiddle: action.data.options,
        },
      }
    case variantActionTypes.setSlotSelectedOptionsRightRailLower:
      return {
        ...state,
        slotsSelectedOptions: {
          ...state.slotsSelectedOptions,
          rightRailLower: action.data.options,
        },
      }

    /**
     * Slots
     */
    case variantActionTypes.setSlotBelowResponseUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowResponse: {
            ...state.slots.belowResponse,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotAboveCorrectAnswerUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          aboveCorrectAnswer: {
            ...state.slots.aboveCorrectAnswer,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowCorrectAnswerUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowCorrectAnswer: {
            ...state.slots.belowCorrectAnswer,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotAboveHeadlineUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          aboveHeadline: {
            ...state.slots.aboveHeadline,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowHeadlineUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowHeadline: {
            ...state.slots.belowHeadline,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotAboveButtonsUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          aboveButtons: {
            ...state.slots.aboveButtons,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowButtons1UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowButtons1: {
            ...state.slots.belowButtons1,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowButtons2UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowButtons2: {
            ...state.slots.belowButtons2,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowButtons3UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowButtons3: {
            ...state.slots.belowButtons3,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowSecondButtons1UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowSecondButtons1: {
            ...state.slots.belowSecondButtons1,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowSecondButtons2UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowSecondButtons2: {
            ...state.slots.belowSecondButtons2,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotBelowSecondButtons3UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          belowSecondButtons3: {
            ...state.slots.belowSecondButtons3,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotPageLevel1UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          pageLevel1: {
            ...state.slots.pageLevel1,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotPageLevel2UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          pageLevel2: {
            ...state.slots.pageLevel2,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotPageLevel3UnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          pageLevel3: {
            ...state.slots.pageLevel3,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotRightRailUpperUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          rightRailUpper: {
            ...state.slots.rightRailUpper,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotRightRailMiddleUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          rightRailMiddle: {
            ...state.slots.rightRailMiddle,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setSlotRightRailLowerUnitId:
      return {
        ...state,
        slots: {
          ...state.slots,
          rightRailLower: {
            ...state.slots.rightRailLower,
            unitId: action.data.unitId,
          },
        },
      }

    /**
     * Default Slots
     */

    case variantActionTypes.setDefaultSlotBelowResponseUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowResponse: {
            ...state.slots.aboveCorrectAnswer,
            unitId: action.data.unitId,
          },
        },
      }

    case variantActionTypes.setDefaultSlotAboveCorrectAnswerUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          aboveCorrectAnswer: {
            ...state.slots.aboveCorrectAnswer,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowCorrectAnswerUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowCorrectAnswer: {
            ...state.slots.belowCorrectAnswer,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotAboveHeadlineUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          aboveHeadline: {
            ...state.slots.aboveHeadline,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowHeadlineUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowHeadline: {
            ...state.slots.belowHeadline,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotAboveButtonsUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          aboveButtons: {
            ...state.slots.aboveButtons,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowButtons1UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowButtons1: {
            ...state.slots.belowButtons1,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowButtons2UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowButtons2: {
            ...state.slots.belowButtons2,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowButtons3UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowButtons3: {
            ...state.slots.belowButtons3,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowSecondButtons1UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowSecondButtons1: {
            ...state.slots.belowSecondButtons1,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowSecondButtons2UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowSecondButtons2: {
            ...state.slots.belowSecondButtons2,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotBelowSecondButtons3UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          belowSecondButtons3: {
            ...state.slots.belowSecondButtons3,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotPageLevel1UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          pageLevel1: {
            ...state.slots.pageLevel1,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotPageLevel2UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          pageLevel2: {
            ...state.slots.pageLevel2,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotPageLevel3UnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          pageLevel3: {
            ...state.slots.pageLevel3,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotRightRailUpperUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          rightRailUpper: {
            ...state.slots.rightRailUpper,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotRightRailMiddleUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          rightRailMiddle: {
            ...state.slots.rightRailMiddle,
            unitId: action.data.unitId,
          },
        },
      }
    case variantActionTypes.setDefaultSlotRightRailLowerUnitId:
      return {
        ...state,
        defaultSlots: {
          ...state.defaultSlots,
          rightRailLower: {
            ...state.slots.rightRailLower,
            unitId: action.data.unitId,
          },
        },
      }

    /**
     * Minimal load delay
     */

    case variantActionTypes.setMinDelay:
      return {
        ...state,
        loadDelayMin: action.data.loadDelayMin,
      }

    /**
     * Maximal load delay
     */

    case variantActionTypes.setMaxDelay:
      return {
        ...state,
        loadDelayMax: action.data.loadDelayMax,
      }
    /**
     * Minimal load delay warning message
     */

    case variantActionTypes.setMinDelayWarning:
      return {
        ...state,
        loadDelayMinWarning: action.data.loadDelayMinWarning,
      }

    /**
     * Maximal load delay warning message
     */

    case variantActionTypes.setMaxDelayWarning:
      return {
        ...state,
        loadDelayMaxWarning: action.data.loadDelayMaxWarning,
      }
    /**
     * Notes
     */

    case variantActionTypes.setNotes:
      return {
        ...state,
        notes: action.data.notes,
      }

    /**
     * Default Notes
     */

    case variantActionTypes.setDefaultNotes:
      return {
        ...state,
        defaultNotes: action.data.notes,
      }

    /**
     * Set Initial Values
     */

    case variantActionTypes.resetVariantState:
      return initialVariantState

    default:
      throw new Error(`"${action.type}" is not a valid action type!`)
  }
}

import gql from "graphql-tag"
import {
  ChangeQuiz,
  ChangeQuizVariables,
  CreateQuiz,
  CreateQuizVariables,
  DeleteQuiz,
  DeleteQuizVariables,
  Quizzes,
  QuizzesVariables,
} from "../../types"
import { QUERY_QUIZZES } from "../queries"
import { MutationFunction, MutationResult } from "react-apollo"
import { cloneDeep } from "lodash"
import { fragments } from "model/graphql/fragments"
import { MutationUpdaterFn } from "apollo-client"

/**
 * Delete Quiz Mutation
 */

export const MUTATION_DELETE_QUIZ = gql`
  mutation DeleteQuiz($id: ID!) {
    quizDelete(input: { id: $id }) {
      deleted
      clientMutationId
    }
  }
`
/**
 * Delete Hook's Args
 */

type UseDeleteQuizArgs = {
  queryVariables: QuizzesVariables
  onSuccessAction: () => void
}

/**
 * Delete Quiz Hook
 * @param args
 */

export const useDeleteQuiz = (args: UseDeleteQuizArgs) => {
  const { onSuccessAction, queryVariables } = args

  const deleteQuiz = (deleteQuizMutation: DeleteQuizMutation, variables: DeleteQuizVariables) => async () => {
    try {
      await deleteQuizMutation({
        variables,
        update: updateQuizzes({
          quizId: variables.id,
          queryVariables,
        }),
      })
      onSuccessAction()
    } catch (error) {
      console.error(error)
    }
  }

  type UpdateQuizzesArgs = {
    quizId: string
    queryVariables: QuizzesVariables
  }

  const updateQuizzes = (args: UpdateQuizzesArgs): DeleteQuizMutationUpdater => (cache, payload) => {
    const { quizId, queryVariables } = args

    if (!payload.data.quizDelete.deleted) {
      return
    }
    try {
      const data: Quizzes = cloneDeep(cache.readQuery({ query: QUERY_QUIZZES, variables: queryVariables }))

      data.quizzes.edges = data.quizzes.edges.filter(({ node: quiz }) => quiz.id !== quizId)

      cache.writeQuery({
        query: QUERY_QUIZZES,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { deleteQuiz }
}

/**
 * Delete Quiz Mutation Types
 */

export type DeleteQuizMutation = MutationFunction<DeleteQuiz, DeleteQuizVariables>
export type DeleteQuizMutationResult = MutationResult<DeleteQuiz>
export type DeleteQuizMutationUpdater = MutationUpdaterFn<DeleteQuiz>

/**
 * Create Quiz
 */

// Mutation

export const MUTATION_QUIZ_CREATE = gql`
  mutation CreateQuiz($authorId: ID!, $sortBy: String, $desc: Boolean) {
    quizCreate(input: { title: "New Quiz", slug: "new-quiz", authorId: $authorId, sortBy: $sortBy, desc: $desc }) {
      quiz {
        ...QuizData
      }
      quizzes {
        edges {
          node {
            ...QuizData
          }
        }
      }
      clientMutationId
    }
  }
  ${fragments.quiz}
`

// Hook

export type UseCreateQuizArgs = {
  queryVariables: QuizzesVariables
  onSuccessAction: (quizId: string) => void
}

export const useCreateQuiz = (args: UseCreateQuizArgs) => {
  const { queryVariables, onSuccessAction } = args

  const createQuiz = async (createQuizMutation: CreateQuizMutation, variables: CreateQuizVariables) => {
    try {
      const result = (await createQuizMutation({
        variables,
        update: updateQuizzes,
      })) as CreateQuizMutationResult
      const { id: quizId } = result.data.quizCreate.quiz
      onSuccessAction(quizId)
    } catch (error) {
      console.error(error)
    }
  }

  const updateQuizzes: CreateQuizMutationUpdater = (cache, payload) => {
    if (!payload.data.quizCreate) {
      return
    }
    try {
      const { quizzes } = payload.data.quizCreate
      const data: Quizzes = cloneDeep(cache.readQuery({ query: QUERY_QUIZZES, variables: queryVariables }))

      data.quizzes = quizzes

      cache.writeQuery({
        query: QUERY_QUIZZES,
        variables: queryVariables,
        data: data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { createQuiz, updateQuizzes }
}

/**
 * Create Quiz Mutation Types
 */

export type CreateQuizMutation = MutationFunction<CreateQuiz, CreateQuizVariables>
export type CreateQuizMutationResult = MutationResult<CreateQuiz>
export type CreateQuizMutationUpdater = MutationUpdaterFn<CreateQuiz>

/**
 * Change Quiz Mutation
 */

export const MUTATION_CHANGE_QUIZ = gql`
  mutation ChangeQuiz(
    $title: String
    $slug: String
    $customSlug: Boolean
    $featuredImage: Upload
    $deleteFeaturedImage: Boolean
    $description: String
    $authorId: ID!
    $tagIds: [ID]
    $includeAtHomepage: Boolean
    $id: ID!
  ) {
    quizChange(
      input: {
        title: $title
        slug: $slug
        customSlug: $customSlug
        featuredImage: $featuredImage
        deleteFeaturedImage: $deleteFeaturedImage
        description: $description
        authorId: $authorId
        tagIds: $tagIds
        includeAtHomepage: $includeAtHomepage
        id: $id
      }
    ) {
      clientMutationId
      quiz {
        ...QuizData
      }
    }
  }
  ${fragments.quiz}
`

/**
 * Change Quiz Mutation Types
 */

export type ChangeQuizMutation = MutationFunction<ChangeQuiz, ChangeQuizVariables>

import gql from "graphql-tag"

export const fragments = {
  user: gql`
    fragment UserData on UserNode {
      id
      firstName
      lastName
      avatar
      email
      isActive
      quizzesCount
      username
      description
      groups {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,

  group: gql`
    fragment GroupData on GroupNode {
      id
      name
      userSet {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
            isActive
            username
            quizzesCount
          }
        }
      }
    }
  `,
  vendor: gql`
    fragment VendorData on VendorNode {
      id
      name
      initialScript
      adsTxtEntry
    }
  `,
  unit: gql`
    fragment UnitData on UnitNode {
      id
      name
      persistent
      initialScript
      refreshScript
      wipeScript
      slotCompatibility
    }
  `,
  layout: gql`
    fragment LayoutData on LayoutNode {
      id
      name
      default
    }
  `,
  variant: gql`
    fragment VariantData on VariantNode {
      id
      default
      pageFrom
      pageTo
      notes
      loadDelayMin
      loadDelayMax
    }
  `,
  slot: gql`
    fragment SlotData on SlotNode {
      id
      slot
    }
  `,
  rule: gql`
    fragment RuleData on RuleNode {
      id
      name
      utmSource
    }
  `,
  quiz: gql`
    fragment QuizData on QuizNode {
      id
      title
      createdAt
      publishedAt
      description
      includeAtHomepage
      featuredImage
      slug
      customSlug
      tags {
        edges {
          node {
            id
            slug
            title
          }
        }
      }

      author {
        firstName
        lastName
        id
      }

      isSlugUnique
    }
  `,
  question: gql`
    fragment QuestionData on QuestionNode {
      id
      order
      question
      image
      type
      answerExplain
      explainImage
      quiz {
        id
      }
    }
  `,
  answer: gql`
    fragment AnswerData on AnswerNode {
      id
      order
      answer
      correct
      image
      question {
        id
      }
    }
  `,

  tag: gql`
    fragment TagData on TagNode {
      id
      slug
      title
      quizSet {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  `,
}

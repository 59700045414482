import React from "react"
import styled from "styled-components"
import { theme } from "theme"
import imagePlaceholder from "screens/layout/variant-preview/slot-sections/image-placeholder.png"

type Props = {
  children?: React.ReactNode
}

export const SlotsTopSection = (props: Props) => {
  return (
    <Container>
      <PageContent1 />
      <PageContent2>
        <div className={"line heading-line"} />
        <div className={"line first-line"} />
        <div className={"line second-line"} />
        <div className={"line third-line"} />
        <div className={"empty-image"}>
          <img src={imagePlaceholder} alt="placeholder" />
        </div>
      </PageContent2>

      {props.children}
    </Container>
  )
}

const Container = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, max-content);
  grid-row-gap: ${theme.offset3};
  grid-template-areas:
    "below_response"
    "page-content-1"
    "above_correct_answer"
    "page-content-2"
    "below_correct_answer";
  padding: ${theme.offset3};
  width: 100%;
  border-bottom: 1px solid ${theme.color.textNonactive};
  border-top-left-radius: ${theme.rounding1};
  grid-area: slots-top-section;
`

const PageContent1 = styled("div")`
  display: block;
  position: relative;
  justify-self: center;
  width: 120px;
  height: 20px;
  border-radius: 10px;
  background-color: ${theme.color.textNonactive};
  padding: 0 20px;
  grid-area: page-content-1;
`

const PageContent2 = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  width: 100%;
  grid-area: page-content-2;

  .line {
    display: block;
    position: relative;
    background-color: ${theme.color.textNonactive};
  }

  .heading-line {
    width: 174px;
    height: 10px;
    border-radius: 5px;
  }

  .first-line {
    width: 90%;
    height: 6px;
    margin-top: 10px;
    border-radius: 3px;
  }

  .second-line {
    width: 50%;
    height: 6px;
    margin-top: 6px;
    border-radius: 3px;
  }

  .third-line {
    width: 75%;
    height: 6px;
    margin-top: 6px;
    border-radius: 3px;
  }

  .empty-image {
    display: block;
    position: relative;
    background-color: ${theme.color.backgroundGray};
    border: 1px solid ${theme.color.textNonactive};
    width: 100%;
    height: 120px;
    margin-top: 20px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }
`

import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { theme } from "theme"
import { Box } from "components/box"
import { Label } from "components/label"
import { Input } from "components/input"
import { MUTATION_CHANGE_QUIZ } from "model/graphql/mutations"
import { useDispatch, useSelector } from "react-redux"
import { getImageSource, QuizGeneralDispatch, useIsMounted, useSelectOptionsFilter } from "utils"
import { quizGeneralActionTypes } from "redux-reducers"
import { ReduxState } from "redux-store"
import { isEqual, includes } from "lodash"
import {
  changeQuizAuthorId,
  changeQuizDescription,
  changeQuizFeaturedImageFile,
  changeQuizFeaturedImagePreview,
  changeQuizIncludeAtHomepage,
  changeQuizSlug,
  changeQuizTagIds,
  changeQuizTitle,
  debounceFn,
} from "screens/quiz/general/quiz-form/helpers"
import { PermalinkTextarea, Textarea } from "components/textarea"
import { Checkbox } from "components/checkbox"
import { ImageUpload } from "components/image-upload"
import { Select } from "components/select"
import { CheckButton } from "components/check-button"
import { Warning } from "components/warning"
import { useMutation } from "react-apollo"
import { QuizQueryResult } from "screens/quiz/general/quiz-general-screen"

/**
 * Component
 */

type Props = {
  data: QuizQueryResult["data"]
  children?: never
}

export const QuizForm = React.memo((props: Props) => {
  const { data } = props

  const [changeQuizMutation] = useMutation(MUTATION_CHANGE_QUIZ)

  const quiz = useSelector((state: ReduxState) => state.quizGeneral, isEqual)
  const dispatch = useDispatch()

  const [selectOptions, setSelectOptions] = useState([])
  const [quizTitle, setQuizTitle] = useState(data.quiz.title)
  const [quizSlug, setQuizSlug] = useState("")
  const [slugWarning, setSlugWarning] = useState("")

  useEffect(() => {
    // Do not handle warning message on first load => will create false duplicate warning
    if (!quizSlug.length) {
      setQuizSlug(data.quiz.slug)
    } else {
      if (data.quiz.isSlugUnique) {
        setSlugWarning("")
        dispatch<QuizGeneralDispatch>({
          type: quizGeneralActionTypes.setQuizGeneralSlugWarning,
          data: { slugWarning: "" },
        })
      } else {
        setSlugWarning("This slug has already been used")
        dispatch<QuizGeneralDispatch>({
          type: quizGeneralActionTypes.setQuizGeneralSlugWarning,
          data: { slugWarning: "This slug has already been used" },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /**
   * Do not render if QuizForm is unmounted
   */

  useIsMounted()

  /**
   * Set Quiz Data Into State On A First Render
   */

  const lastTitle = useRef(data.quiz.title)

  useEffect(() => {
    dispatch<QuizGeneralDispatch>({
      type: quizGeneralActionTypes.setQuizGeneralData,
      data: {
        id: data.quiz.id,
        title: data.quiz.title,
        slug: data.quiz.slug,
        isSlugCustom: data.quiz.customSlug,
        description: data.quiz.description,
        tagIds: data.quiz.tags.edges.map(({ node: tag }) => tag.id),
        authorId: data.quiz.author.id,
        featuredImagePreview: data.quiz.featuredImage ? getImageSource(data.quiz.featuredImage) : "",
        featuredImageFile: null,
        includeAtHomepage: data.quiz.includeAtHomepage,
        selectedAuthor: { id: data.quiz.author.id, name: `${data.quiz.author.firstName} ${data.quiz.author.lastName}` },
        isPublished: Boolean(data.quiz.publishedAt),
      },
    })

    setQuizTitle(data.quiz.title)
    setQuizSlug(data.quiz.slug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  /**
   * Form Changes
   */

  const changeDataArgs = {
    quizState: quiz,
    debounceFn,
    dispatch,
    changeQuizMutation,
  }

  const changeTitle = changeQuizTitle(changeDataArgs)
  const changeToLastTitle = () => {
    if (quiz.title === "") {
      changeTitle(lastTitle.current)
    }
  }

  const onChangeTitle = (title: string) => {
    setQuizTitle(title)
    changeQuizTitle(changeDataArgs)(title)
  }

  const onChangeSlug = (slug: string) => {
    setQuizSlug(slug)
    changeQuizSlug(changeDataArgs)(slug)

    if (!slug.length) {
      setSlugWarning("Slug cannot be empty")
      dispatch<QuizGeneralDispatch>({
        type: quizGeneralActionTypes.setQuizGeneralSlugWarning,
        data: { slugWarning: "Slug cannot be empty" },
      })
    }
  }

  const changeDescription = changeQuizDescription(changeDataArgs)

  const changeTags = changeQuizTagIds(changeDataArgs, quiz.tagIds)

  const changeAuthor = changeQuizAuthorId(changeDataArgs)

  const changeIncludeAtHomepage = changeQuizIncludeAtHomepage(changeDataArgs)

  const changeFeaturedImagePreview = changeQuizFeaturedImagePreview({ dispatch })

  const changeFeaturedImageFile = changeQuizFeaturedImageFile(changeDataArgs)

  /**
   * Select Options + Options Filter
   */

  useEffect(() => {
    const selectOptions = data.users.edges.map(({ node: user }) => ({
      id: user.id,
      value: `${user.firstName} ${user.lastName}`,
    }))
    setSelectOptions(selectOptions)
  }, [data.users.edges])

  const { searchValue, setSearchValue, filteredSelectOptions } = useSelectOptionsFilter(selectOptions)

  /**
   * JSX
   */

  const slugPrefix = process.env.REACT_APP_SLUG

  return (
    <FormWrapper>
      <Box>
        <Form>
          {/* Quiz Title*/}
          <FormRow gridArea={"title"}>
            <Label>{`Quiz Title`}</Label>
            <Input value={quizTitle} onChange={val => onChangeTitle(val)} onBlur={changeToLastTitle} />
          </FormRow>

          {/* Permalink */}
          <FormRow gridArea={"permalink"} topOffset={theme.offset1}>
            <Label>{`Permalink`}</Label>
            <PermalinkTextarea
              prefix={slugPrefix}
              onChange={val => onChangeSlug(val)}
              value={quizSlug}
              hasWarning={Boolean(slugWarning)}
            />
            <Warning message={slugWarning} />
          </FormRow>

          {/* About */}
          <FormRow gridArea={"about"} topOffset={theme.offset3}>
            <Label>{`About`}</Label>
            <Textarea value={quiz.description} onChange={changeDescription} hasStaticHeight={true} />
          </FormRow>

          {/* Categories */}
          <FormRow gridArea={"categories"} topOffset={theme.offset3}>
            <Label>{`Categories`}</Label>
            <CategoriesWrapper>
              {data.tags.edges.map(({ node: tag }) => (
                <CategoryWrapper key={tag.id}>
                  <CheckButton onChange={changeTags} optionValue={tag.id} isChecked={includes(quiz.tagIds, tag.id)}>
                    {tag.title}
                  </CheckButton>
                </CategoryWrapper>
              ))}
            </CategoriesWrapper>
          </FormRow>

          {/* Author */}
          <FormRow gridArea={"author"}>
            <Label>{`Author`}</Label>
            <Select
              optionsCount={selectOptions.length}
              selectedOption={quiz.selectedAuthor}
              options={filteredSelectOptions}
              onSelect={changeAuthor}
              search={{ placeholder: "start typing", value: searchValue, onChange: setSearchValue }}
            />
          </FormRow>

          {/* Featured */}
          <FormRow gridArea={"featured"} topOffset={theme.offset1}>
            <Label>{`Featured`}</Label>
            <CheckboxWrapper>
              <Checkbox
                isChecked={quiz.includeAtHomepage}
                onChange={changeIncludeAtHomepage}
              >{`Display in the homepage`}</Checkbox>
            </CheckboxWrapper>
          </FormRow>

          {/*Featured Image*/}
          <ImageUploadWrapper>
            <ImageUpload
              setImageFile={changeFeaturedImageFile}
              setImagePreview={changeFeaturedImagePreview}
              previewImage={quiz.featuredImagePreview}
            />
          </ImageUploadWrapper>
        </Form>
      </Box>
    </FormWrapper>
  )
})

/**
 * Styles
 */

const FormWrapper = styled("div")`
  display: block;
  position: relative;
  width: 100%;
  max-width: 1000px;
`

const Form = styled("form")`
  display: grid;
  position: relative;
  padding: ${theme.offset3};
  grid-template-columns: 680px 200px;
  grid-column-gap: ${theme.offset3};
  grid-template-areas:
    "title image"
    "permalink image"
    "about image"
    "categories image"
    "author image"
    "featured image";
`

type FormRowProps = {
  gridArea: string
  topOffset?: string
}

const FormRow = styled("div")`
  display: grid;
  position: relative;
  grid-template-columns: 120px 1fr;
  grid-template-rows: max-content max-content max-content;
  grid-template-areas:
    "label ."
    ". warning"
    ". slug";
  grid-column-gap: 10px;
  align-items: start;
  grid-area: ${(props: FormRowProps) => props.gridArea};
  margin-top: ${(props: FormRowProps) => props.topOffset || "0px"};
`

const CheckboxWrapper = styled("div")`
  display: flex;
  position: relative;
  height: 100%;
  align-items: flex-end;
`

const ImageUploadWrapper = styled("div")`
  display: block;
  position: relative;
  grid-area: image;
`

const CategoriesWrapper = styled("div")`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-content: space-between;
`

const CategoryWrapper = styled("div")`
  padding-right: 20px;
  margin-bottom: 20px;
`
